import GoogleMapReact from 'google-map-react'
import React from 'react'

interface GmapProps {
  pin?: {
    lat?: number
    lng?: number
  }
  poslat?: number
  poslng?: number
  zoom?: number
  apiKey?: any
}

const Gmap = ({ pin, poslat, poslng, zoom, apiKey = '' }: GmapProps) => {
  const mapsdefault = {
    center: {
      lat: poslat || 60.1639836,
      lng: poslng || 24.943864,
    },
    zoom: zoom || 13,
  }

  const handleApiLoaded = (map: any, maps: any) => {
    if (pin?.lat && pin?.lng) {
      new maps.Marker({
        position: pin,
        map,
        title: 'Teknisen Kaupan Liitto ry',
      })
    }
  }

  return (
    <div className="mb-14" style={{ height: '420px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={mapsdefault.center}
        defaultZoom={mapsdefault.zoom}
        onGoogleApiLoaded={({ map, maps }: any) => handleApiLoaded(map, maps)}
      ></GoogleMapReact>
    </div>
  )
}

export default Gmap
